







































































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
// components

import TextInput from '@/components/_uikit/controls/TextInput.vue'
import VKIDButton from '@/components/VKIDButton.vue'
import Dialog from '@/components/modals/Dialog.vue'
import ModalWrapper from '@/components/modals/ModalWrapper.vue'
// store
import AuthModule from '@/store/modules/auth'
// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
import VKIDMixin from '@/mixins/VKIDMixin'
// types
import { AuthLogin } from '@/store/types'

@Component({
  components: {
    TextInput,
    VKIDButton,
    ValidationObserver,
    ValidationProvider,
    Dialog,
    ModalWrapper,
  },
})
export default class LoginModal extends Mixins(NotifyMixin, VKIDMixin) {
  @Prop({ default: false })
  private visible!: boolean

  @Prop({ default: '' })
  private courseTitle!: string

  private localVisible = this.visible

  private cityID = 510
  private form: AuthLogin = {
    email: '',
    password: '',
  }

  private isFocusPassword = false
  private typePassword = true

  private handleLogin() {
    const form: any = this.$refs.form
    form.validate()
      .then(async (result: boolean) => {
        if(result) {
          requestAnimationFrame(() => (form.reset()))
          AuthModule.fetchUserCity()
            .then(response => {
              this.cityID = response.id ?? 510
            })
          this.$emit('login', { ...this.form, cityId: this.cityID })
          this.$emit('close')
        } else {
          this.notifyError('Проверьте введенные данные')
        }
      })
  }

  private handleClose() {
    this.$emit('update:visible', false)
  }

  private showRegisterModal() {
    this.$emit('showRegisterModal')
    this.$emit('close')
  }

  private showRestoreModal() {
    this.$emit('showRestoreModal')
    this.$emit('close')
  }

  @Watch('visible')
  watchVisible(value: boolean) {
    this.localVisible = value
  }
}
