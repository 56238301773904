



















































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import { Bind, Debounce } from 'lodash-decorators'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

import TextInput from '@/components/_uikit/controls/TextInput.vue'
import Dialog from '@/components/modals/Dialog.vue'
import ModalWrapper from '@/components/modals/ModalWrapper.vue'
// store
import AuthModule from '@/store/modules/auth'
// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
// types
import { AuthRestore } from '@/store/types'

@Component({
  components: {
    TextInput,
    ValidationObserver,
    ValidationProvider,
    Dialog,
    ModalWrapper,
  },
})
export default class RestoreModal extends Mixins(NotifyMixin) {
  @Prop({ default: false })
  private visible!: boolean

  private localVisible = this.visible

  private scrollOptions = {
    scrollPanel: {
      scrollingX: false,
    },
  }

  private isSent = false

  private form: AuthRestore = {
    email: '',
  }

  @Debounce(300)
  @Bind
  private handleRestore() {
    const form: any = this.$refs.form

    form.validate()
      .then(async (result: boolean) => {
        if (result) {
          AuthModule.restore(this.form)
            .then(() => {
              requestAnimationFrame(() => (form.reset()))
              this.isSent = true
            })
            .catch(this.notifyError)
        } else {
          this.notifyError('Проверьте введенные данные')
        }
      })
  }

  private showLoginModal() {
    this.$emit('showLoginModal')
    this.$emit('close')
  }

  @Watch('visible')
  watchVisible(value: boolean) {
    this.localVisible = value
  }
}
