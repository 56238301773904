var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Dialog',{attrs:{"visible":_vm.localVisible,"width":600,"persistent":false,"contentClass":"no-margin-mobile"},on:{"closeDialog":function($event){return _vm.handleClose(true)}}},[_c('VCard',{attrs:{"width":"600"}},[_c('VCard',{staticClass:"register-modal"},[_c('div',{staticClass:"register-modal__container"},[_c('div',{staticClass:"register-modal__header space-b-8"},[_c('div',{staticClass:"register-modal__header-wrapper"},[_c('div',{staticClass:"text-h3-1 space-r-4 register-modal__header-title"},[_vm._v("Покупка курса")]),_vm._t("headerHint")],2),_vm._t("headerBtn"),_c('VBtn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('close')}}},[_c('VIcon',[_vm._v("$close")])],1)],2),_c('div',{staticClass:"register-modal__body"},[_c('p',[_vm._v("Нужно войти в профиль или зарегистрироваться, чтобы оплатить курс"),_c('span',{staticClass:"primary--text"},[_vm._v(" "+_vm._s(_vm.courseTitle))])]),_c('ValidationObserver',{ref:"form",staticClass:"space-b-8",attrs:{"tag":"div"}},[_c('div',{staticClass:"register-modal__form-grid form-grid space-b-8"},[_c('ValidationProvider',{staticClass:"form-grid__field form-grid__field--stretch",attrs:{"name":"email","rules":"required|email","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{ref:"focus",attrs:{"name":"email","label":"Почта*","placeholder":"Введите почту","autocomplete":"new-password","invalid":errors.length > 0,"error":errors[0]},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})]}}])}),_c('ValidationProvider',{staticClass:"form-grid__field form-grid__field--stretch",attrs:{"name":"city","rules":"requiredSelect","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('Autocomplete',{attrs:{"name":"city","label":"Город*","options":_vm.cities,"placeholder":"Выберите город","invalid":errors.length > 0,"error":errors[0]},model:{value:(_vm.form.cityId),callback:function ($$v) {_vm.$set(_vm.form, "cityId", $$v)},expression:"form.cityId"}})]}}])}),_c('ValidationProvider',{staticClass:"form-grid__field",attrs:{"name":"password","rules":"required|min:8|password:@passwordConfirmation","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{class:{ 'append-icon': true, 'append-icon_active': !_vm.typePassword },attrs:{"name":"password","label":"Пароль*","type":_vm.typePassword ? 'password' : 'text',"placeholder":"Введите пароль","appendIcon":!_vm.isFocusPassword ? undefined : _vm.typePassword ? '$eyeClose' : '$eye',"autocomplete":"new-password","invalid":errors.length > 0,"error":errors[0]},on:{"click:append":function($event){_vm.typePassword = !_vm.typePassword},"focus":function($event){_vm.isFocusPassword = true},"blur":function($event){_vm.isFocusPassword = false}},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})]}}])}),_c('ValidationProvider',{staticClass:"form-grid__field",attrs:{"name":"passwordConfirmation","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{class:{ 'append-icon': true, 'append-icon_active': !_vm.typeConfirmationPassword },attrs:{"name":"passwordConfirmation","label":"Повторите пароль*","type":_vm.typeConfirmationPassword ? 'password' : 'text',"placeholder":"Повторите пароль","appendIcon":!_vm.isFocusConfirmationPassword ? undefined : _vm.typeConfirmationPassword ? '$eyeClose' : '$eye',"invalid":errors.length > 0,"error":errors[0]},on:{"click:append":function($event){_vm.typeConfirmationPassword = !_vm.typeConfirmationPassword},"focus":function($event){_vm.isFocusConfirmationPassword = true},"blur":function($event){_vm.isFocusConfirmationPassword = false},"enter":_vm.handleRegister},model:{value:(_vm.form.passwordConfirmation),callback:function ($$v) {_vm.$set(_vm.form, "passwordConfirmation", $$v)},expression:"form.passwordConfirmation"}})]}}])})],1),_c('div',{staticClass:"form-grid__docs"},[_c('ValidationProvider',{staticClass:"form-grid__agreement",attrs:{"name":"agreement-conf","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VCheckbox',{attrs:{"name":"agreement-conf","error":errors.length > 0},model:{value:(_vm.agreementConfidential),callback:function ($$v) {_vm.agreementConfidential=$$v},expression:"agreementConfidential"}}),_c('div',{staticClass:"text-body-1"},[_c('span',[_vm._v("Я ознакомлен с")]),_c('LinkPrimary',{attrs:{"tag":"a","target":"_blank","href":_vm.docsLink.policy}},[_vm._v(" политикой конфиденциальности")])],1)]}}])}),_c('ValidationProvider',{staticClass:"form-grid__agreement",attrs:{"name":"agreement-personal-data","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VCheckbox',{attrs:{"name":"agreement-personal-data","error":errors.length > 0},model:{value:(_vm.agreementPersonal),callback:function ($$v) {_vm.agreementPersonal=$$v},expression:"agreementPersonal"}}),_c('div',{staticClass:"text-body-1"},[_c('span',[_vm._v("Я даю")]),_c('LinkPrimary',{attrs:{"tag":"a","target":"_blank","href":_vm.docsLink.personal}},[_vm._v(" согласие на обработку персональных данных")])],1)]}}])})],1)])],1),_c('div',{staticClass:"register-modal__footer"},[_c('VBtn',{staticClass:"register-modal__button",attrs:{"height":"44","color":"primary","text":""},nativeOn:{"click":function($event){return _vm.showLoginModal($event)}}},[_vm._v("Уже есть профиль")]),_c('VBtn',{staticClass:"register-modal__button",attrs:{"height":"44","color":"primary"},nativeOn:{"click":function($event){return _vm.handleRegister($event)}}},[_vm._v("Зарегистрироваться")]),_c('div',{ref:"captcha",staticClass:"g-recaptcha",staticStyle:{"display":"none"}})],1),_c('div',{staticClass:"register-modal__vk"},[_c('VKIDButton',{on:{"click":function($event){return _vm.handleLoginVKBuyLink(_vm.$route.params.hash)}}})],1)])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }