




















































































































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

// components
import Autocomplete from '@/components/_uikit/controls/Autocomplete.vue'
import TextInput from '@/components/_uikit/controls/TextInput.vue'
import VKIDButton from '@/components/VKIDButton.vue'
import Dialog from '@/components/modals/Dialog.vue'
import ModalWrapper from '@/components/modals/ModalWrapper.vue'
// store
import DictionaryModule from '@/store/modules/dictionary'
import AuthModule from '@/store/modules/auth'
// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
import VKIDMixin from '@/mixins/VKIDMixin'
// types
import { AuthRegister } from '@/store/types'
import { DocsEnum } from '@/store/types/docs'

@Component({
  components: {
    TextInput,
    Autocomplete,
    VKIDButton,
    ValidationObserver,
    ValidationProvider,
    Dialog,
    ModalWrapper,
  },
})
export default class RegisterModal extends Mixins(NotifyMixin, VKIDMixin) {
  @Prop({ default: false })
  private visible!: boolean

  @Prop({ default: '' })
  private courseTitle!: string

  private localVisible = this.visible

  private isFocusPassword = false
  private typePassword = true
  private isFocusConfirmationPassword = false
  private typeConfirmationPassword = true

  private agreementPersonal = false
  private agreementConfidential = false

  private docsLink = {
    offerEytutis: DocsEnum.OFFER_EYTUTIS,
    offerMalyshev: DocsEnum.OFFER_MALYSHEV,
    offerRogovenko: DocsEnum.OFFER_ROGOVENKO,
    offerSergeeva: DocsEnum.OFFER_SERGEEVA,
    offerSharafieva: DocsEnum.OFFER_SHARAFIEVA,
    offerStrelkova: DocsEnum.OFFER_STRELKOVA,
    offerVolvaka: DocsEnum.OFFER_VOLVAKA,
    personal: DocsEnum.PERSONAL,
    policy: DocsEnum.POLICY,
  }

  private get cities () {
    return DictionaryModule.cities
  }

  private form: AuthRegister = {
    cityId: 0,
    email: '',
    password: '',
    passwordConfirmation: '',
    recaptchaToken: '',
  }

  private fetchUserCity() {
    AuthModule.fetchUserCity()
      .then(response => {
        this.form.cityId = response.id
      })
      // Если запрос на определение города упал или не смог определить город, то по-умолчанию указывается Москва (510 ид)
      .catch(() => this.form.cityId = 510)
  }

  private handleCaptcha(token: string) {
    this.form.recaptchaToken = token
    this.$emit('registration', this.form)
    this.$emit('close')
  }

  private handleErrorCaptcha() {
    this.notifyError('Капча недействительна, перезагрузите страницу')
  }

  private handleRegister() {
    const form: any = this.$refs.form
    form.validate()
      .then(async (result: boolean) => {
        if (result) {
          window.grecaptcha.execute()
        } else {
          this.notifyError('Проверьте введенные данные')
        }
      })
  }

  private handleClose() {
    this.$emit('update:visible', false)
  }

  private showLoginModal() {
    this.$emit('showLoginModal')
    this.$emit('close')
  }

  private mounted() {
    if(!this.cities.length) {
      DictionaryModule.fetchCities()
    }
    this.fetchUserCity()
  }

  private updated() {
    this.$nextTick(() => {
      window.grecaptcha.ready(() => {
        window.grecaptcha.render(this.$refs.captcha, {
          'error-callback': this.handleErrorCaptcha,
          callback: this.handleCaptcha,
          sitekey: process.env.VUE_APP_CAPTCHA_SITEKEY,
          size: 'invisible',
        })
      })
    })
  }

  @Watch('visible')
  watchVisible(value: boolean) {
    this.localVisible = value
  }
}
