





























import { Component, Prop, Watch } from 'vue-property-decorator'

import DetectSafariMixin from '@/mixins/DetectSafariMixin'
import { NameValueResource } from '@/store/types'
import SystemModule from '@/store/modules/system'

@Component({
  inheritAttrs: false,
})
export default class Autocomplete extends DetectSafariMixin {
  @Prop({ default: '' })
  private value!: any

  @Prop({ default: () => ([]) })
  private options!: NameValueResource[]

  @Prop({ default: 'Нет данных' })
  private noDataText!: string

  @Prop({ default: true })
  private outlined!: boolean

  @Prop({ default: false })
  private invalid!: boolean

  @Prop({ default: '' })
  private error!: string

  @Prop({ default: false })
  private chips!: boolean

  @Prop({ default: false })
  private deleteChips!: boolean

  private innerValue = ''
  private autocomplete: HTMLElement | null = null
  private positionScrollY = 0

  private mounted () {
    this.innerValue = this.value
  }

  private handleScroll(height: number) {
    if (this.autocomplete) {
      const changeScroll = height - this.positionScrollY
      const autocompleteTop = +this.autocomplete.style.top.replace('px', '')
      this.autocomplete.style.top = `${autocompleteTop - changeScroll}px`
      this.positionScrollY = height
    }
  }

  private handleFocus() {
    setTimeout(() => {
      this.$bus.$emit('getPositionScrollY')
      this.positionScrollY = SystemModule.scrollY
      this.autocomplete = document.querySelector('.menuable__content__active.v-autocomplete__content') as HTMLElement
      this.$bus.$on('handleScroll', this.handleScroll)
    })
  }

  private handleBlur() {
    this.autocomplete = null
    this.positionScrollY = 0
    this.$bus.$off('handleScroll', this.handleScroll as any)
  }

  private handleInput (value: string) {
    this.innerValue = value
    this.$emit('update:value', this.innerValue)
    this.$emit('input', this.innerValue)
  }

  private deleteChip(item: any) {
    if (Array.isArray(this.innerValue)) {
      for (let i = 0; i < this.innerValue.length; i++) {
        if (this.innerValue[i] === item.value) {
          this.innerValue.splice(i, 1)
          this.$emit('update:value', this.innerValue)
          this.$emit('input', this.innerValue)
        }
      }
    }
  }

  @Watch('value')
  private watchValue (value: string) {
    this.innerValue = value
  }
}
